<template>
  <div class="otherOrderDelivery">
    <p class="title">行业动态</p>
    <div class="content">
      <div class="btnTop">
          <div class="inputTitle"><el-input v-model="input_title_text" placeholder="请输入查询标题"></el-input></div>
          <el-button type="info" @click="industry_dynamics_listFun(true)">搜索</el-button>
          <el-button type="info" @click="reset_listFun()">重置</el-button>
          <el-button type="primary" @click="doSomeThing()">添加</el-button>
      </div>
      <el-table :data="tableData" stripe height="640" v-loading="loading">
        <el-table-column
          prop="news_id"
          label="编号"
          align="center"
          width="75"
        ></el-table-column>
        <el-table-column
          prop="news_title"
          label="新闻标题"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="news_count"
          label="点击量"
          align="center"
          width="75"
        ></el-table-column>
        <el-table-column
          prop="priority"
          label="优先级"
          align="center"
          width="75"
        ></el-table-column>
        <el-table-column
          prop="release_time_stamp"
          label="发布时间"
          align="center"
          width="110"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.is_release"
              active-text="已发布"
              inactive-text="未发布"
              @change="industry_dynamics_statusFun(scope.row)"
              >
            </el-switch>
            <el-button
              type="primary"
              size="small"
              @click="doSomeThing(scope.row)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="el_pagination_style">
        <el-pagination
          :hide-on-single-page="total <= 0"
          background
          layout="prev, pager, next,jumper"
          :total="total"
          :current-page.sync="page"
          @current-change="handleCurrentChange"
        />
      </div>

      <div>

      </div>
    </div>
  </div>
</template>
<script>
import apiDetail from '@/api/other.js'
import { Message } from 'element-ui';

export default {
  name: 'industryDynamicsDelivery',
  data() {
    return {
      loading: false,
      tableData: [],
      input_title_text: "",
      isSearch: false,
      size: 10,
      total: 0,
      page: 1,
    }
  },
  mounted() {
    this.industry_dynamics_listFun()
  },
  methods: {
    // 行业动态列表
    industry_dynamics_listFun(is_search = false) {
      this.isSearch = is_search;
      if(is_search&&this.input_title_text === ""){
        Message.warning("请输入标题后搜索！")
        return;
      }
      let param = {
        user_id: JSON.parse(localStorage.getItem("userInfo")).user_id,
        is_backstage: 0,
        size: this.size,
        page: this.page,
      };
      is_search&&this.input_title_text !== ""?param.news_title=this.input_title_text:"";
      apiDetail.industry_dynamics_list(param).then((res) => {
        if(is_search){
          Message.success("搜索到以下数据")
        }
        this.tableData = res.data.data
        this.total = res.data.count
      })
    },
    // 修改行业动态发布状态
    industry_dynamics_statusFun(row) {
      let param = {
        news_id:row.news_id+'',
        is_release:row.is_release?"True":"False",
      }
      apiDetail.industry_dynamics_status(param).then((res) => {
        res.msg === "success"?Message.success("修改成功"):Message.success(res.msg)
      })
    },
    // 重置列表，清除搜索项
    reset_listFun() {
      this.page = 1;
      this.input_title_text = "";
      this.industry_dynamics_listFun();
    },

    //  翻页
    handleCurrentChange(value) {
      this.page = value
      this.industry_dynamics_listFun(this.isSearch)
    },

    // 处理
    doSomeThing(row) {
      this.$router.push({ path: '/industryDynamicsDetail',query: {...row}})
    },
  },
}
</script>
<style lang="scss" scoped>
.otherOrderDelivery {
  margin: 20px;
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #111111;
    margin-bottom: 20px;
  }
  .content {
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    .btnTop {
      text-align: right;
      margin-bottom: 20px;
      .inputTitle{
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
}
</style>