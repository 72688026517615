import request from '@/utils/request'
const api = {
    // 根据uid码查询
    getkits(params) {
        return request({
            url: '/api/kits/kit/',
            method: 'get',
            params
        })
    },
    // 物品申请审批列表
    apply_article_list(params) {
        return request({
            url: '/api/apply_article_list/',
            method: 'get',
            params
        })
    },
    // 行业动态列表
    industry_dynamics_list(params) {
        return request({
            url: '/api/news/',
            method: 'get',
            params
        })
    },
    // 强制刷新发货单
    transfer_single_dispatch(data) {
        return request({
            url: '/api/transfer_single_dispatch/',
            method: 'post',
            data
        })
    },
    // 行业动态详情
    industry_dynamics_info(params) {
        return request({
            url: '/api/news_info/',
            method: 'get',
            params
        })
    },
    // 修改行业动态发布状态
    industry_dynamics_status(data) {
        return request({
            url: '/api/revise_news_status/',
            method: 'post',
            data
        })
    },
    // 修改行业动态详情
    industry_dynamics_putInfo(params) {
        return request({
            url: '/api/add_news/',
            method: 'get',
            params
        })
    },
    // 新增行业动态详情
    industry_dynamics_setInfo(data) {
        return request({
            url: '/api/add_news/',
            method: 'post',
            data
        })
    },
     // 订单发货列表
     order_dispatch_list(params) {
        return request({
            url: '/api/order_dispatch_list/',
            method: 'get',
            params
        })
    },
     // 订单发货详情
     order_dispatch_details(params) {
        return request({
            url: '/api/order_dispatch_details/',
            method: 'get',
            params
        })
    },
     // 新增账号
    create_user(data) {
        return request({
            url: '/api/create_user/',
            method: 'post',
            data
        })
    },
     // 修改密码
    update_password(data) {
        return request({
            url: '/api/update_password/',
            method: 'post',
            data
        })
    },
     // 新增医院
    create_hospital(data) {
        return request({
            url: '/api/create_hospital/',
            method: 'post',
            data
        })
    },
    // // 订单发货上传UDI码 旧 （可删除）
    // create_kit(data) {
    //     return request({
    //         url: '/api/create_kit/',
    //         method: 'post',
    //         data
    //     })
    // },
    // 订单发货上传UDI码 新
    create_dispatch_kit(data) {
        return request({
            url: '/api/create_dispatch_kit/',
            method: 'post',
            data
        })
    },
    // 修改订单发货状态
    update_dispatch_status(data) {
        return request({
            url: '/api/update_dispatch_status/',
            method: 'post',
            data
        })
    },
    // 修改密码
    change_password(data) {
        return request({
            url: '/api/change_password/',
            method: 'post',
            data
        })
    },
    // 查看app版本
    getty_sys_info_list(params) {
        return request({
            url: '/api/ty_sys_info_list/',
            method: 'get',
            params
        })
    },
    // 新增app版本
    postty_sys_info_list(data) {
        return request({
            url: '/api/ty_sys_info_list/',
            method: 'post',
            data
        })
    },
    // 修改app版本
    putty_sys_info_list(data) {
        return request({
            url: '/api/ty_sys_info_list/',
            method: 'put',
            data
        })
    },
    // 删除app版本
    deletety_sys_info_list(data) {
        return request({
            url: '/api/ty_sys_info_list/',
            method: 'delete',
            data
        })
    },
    /**
     * 质检单上相关接口
     */ 
    // 查询质检单批号是否存在,不存在则新建
    check_report_batch(data) {
        return request({
            url: '/api/check_report_batch/',
            method: 'post',
            data
        })
    },
    // 删除文件夹
    delete_oss_file(data) {
        return request({
            url: '/api/delete_oss_file/',
            method: 'post',
            data
        })
    },
    // 删除质检单
    del_reports(data) {
        return request({
            url: '/api/reports/report/',
            method: 'post',
            data
        })
    },

    // 业绩目标新增
    post_sale_target_setting(data) {
        return request({
            url: '/api/sale_target_setting/',
            method: 'post',
            data
        })
    },
    // 业绩目标查询
    get_sale_target_setting(params) {
        return request({
            url: '/api/sale_target_setting/',
            method: 'get',
            params
        })
    },
    // 业绩目标导出
    excel_sale_target_setting(params) {
        return request({
            url: '/api/excel_sale_target_setting/',
            method: 'get',
            params
        })
    },
    // 业绩目标编辑修改
    put_sale_target_setting(params) {
        return request({
            url: '/api/sale_target_setting/'+params.id+'/',
            method: 'put',
            data:params.data
        })
    },
    // 业绩目标编辑修改
    del_sale_target_setting(params) {
        return request({
            url: '/api/sale_target_setting/'+params.id+'/',
            method: 'delete',
        })
    },
    // eGFR列表查询
    egfr_list(params) {
        return request({
            url: '/api/egfr_list/',
            method: 'get',
            params
        })
    },
    // eGFR列表查询
    save_egfr(data) {
        return request({
            url: '/api/save_egfr/',
            method: 'post',
            data
        })
    },
}
export default api